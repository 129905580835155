<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Contents</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-6"
          v-model="filters.keyword"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Contents')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
          @input="debouncedSearch"
        />
      </v-form>
      <FilterTable
        filter-label="Filter Contents"
        filter-key="isActive"
        select-label="Status"
        @filter-change="handleFilterChange"
        :selected-filter="true"
      />

      <v-btn
        fab
        small
        :to="{
          name: 'content',
          params: { id: 0 }
        }"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="isLoading"
      >
        <template v-slot:item.contentKey="{ item }"
          ><router-link :to="{ name: 'content', params: { id: item.id } }">{{ item.contentKey }}</router-link></template
        >
        <template v-slot:item.effective="{ item }">
          <v-chip color="primary" v-if="item.effective">{{ $i18n.translate("Active") }}</v-chip>
          <v-chip color="danger" v-else>{{ $i18n.translate("Inactive") }}</v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import FilterTable from "@/gapp-components/components/tables/FilterTable.vue";
import { debounce } from "lodash";

export default {
  components: {
    FilterTable
  },
  name: "Contents",
  metaInfo: {
    title: "Contents"
  },
  data() {
    return {
      items: [],
      filterByEffective: "Active",
      filters: { keyword: null, isActive: true },
      isLoading: false,
      total: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ["updatedDate"],
        sortDesc: [true]
      },
      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ],
      headers: [
        {
          value: "contentKey",
          text: "Content Key",
          sortable: true
        },

        {
          value: "description",
          text: "Description",
          sortable: true
        },

        {
          value: "effective",
          text: "Status",
          align: "center"
        }
      ]
    };
  },
  methods: {
    onSubmit() {
      this.options.page = 0;
      this.fetchData();
    },
    debouncedSearch: debounce(function() {
      this.fetchData();
    }, 300),
    async fetchData() {
      this.isLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const sortQuery = sortBy.length > 0 ? `&sort=${sortBy[0]},${sortDesc[0] ? "DESC" : "ASC"}` : "";
      try {
        let { data } = await this.$api.post(
          `/api/contents/search?size=${itemsPerPage}&page=${page - 1}${sortQuery}`,
          this.filters
        );
        this.items = data.content;
        this.total = data.totalElements;

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    handleFilterChange(filter) {
      this.filters[filter.key] = filter.value;
      this.fetchData();
    },
    onClear() {
      this.filters.keyword = "";
      this.fetchData();
    }
  },
  watch: {
    options: {
      handler: "fetchData",
      deep: true
    }
  },
  computed: {
    ...mapGetters(["breadcrumbs", "selectedClient", "selectedProgram"])
  },
  mounted() {
    this.fetchData();
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: this.$i18n.translate("Clients"),
        to: { name: "clients" },
        exact: true
      },
      {
        text: this.selectedClient.name,
        to: {
          name: "client",
          params: { id: this.selectedClient.id },
          exact: true
        }
      },
      {
        text: this.$i18n.translate("Programs"),
        to: { name: "programs" },
        exact: true
      },
      {
        text: this.selectedProgram.name,
        to: {
          name: "program",
          params: { id: this.selectedProgram.id },
          exact: true
        }
      },
      {
        text: this.$i18n.translate("Contents"),
        to: { name: "contents" },
        exact: true
      }
    ]);
  }
};
</script>
