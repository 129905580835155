<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn icon class="mr-2" v-on="{ ...onMenu, ...onTooltip }">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Advanced Search") }}</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item-group color="primary">
        <v-subheader>{{ $i18n.translate(filterLabel) }}</v-subheader>
        <v-list-item selectable>
          <v-list-item-content>
            <v-select
              dense
              v-model="computedSelectedFilter"
              :items="filterItems"
              :label="$i18n.translate(selectLabel)"
              clearable
              @change="emitFilterChange"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "FilterTable",
  data() {
    return {
      localSelectedfilter: null
    };
  },
  props: {
    filterItems: {
      type: Array,
      default: () => [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
        { text: "All", value: null }
      ]
    },
    filterLabel: { type: String, default: "Filter" },
    filterKey: { type: String, default: "isActive", required: true },
    selectLabel: { type: String, default: "Status" },
    selectedFilter: { type: Boolean, default: null }
  },
  methods: {
    emitFilterChange(value) {
      this.$emit("filter-change", { key: this.filterKey, value: value });
    }
  },
  computed: {
    computedSelectedFilter: {
      set(v) {
        this.localSelectedfilter = v;
      },
      get() {
        return this.selectedFilter;
      }
    }
  }
};
</script>
